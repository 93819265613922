//@include font-face('Font-Awesome', '/fonts/fontawesome-webfont', 300, normal);

html {
  font-size: 18px;
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  line-height: 1.2;
  color: $color-gray80;
  font-family: $font-family;
  background-color: $color-base;
}

a {
  color: $color-link;
}
img {
  @include img-responsive;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 1rem;
  font-family: "interstate-condensed";
  font-weight: 500;
  line-height: 1;
  color: $color-primary;
  text-transform: uppercase;

  em {
    font-style: normal;
    color: $color-gray10;
  }

  sup {
    position: relative;
    top: -0.6em;
    font-size: 0.5em;
    line-height: 0;
    vertical-align: baseline;
  }

  .memo {
    font-size: 80%;
    font-weight: normal;
  }

  transition: font-size 300ms;
  .svg-inline--fa {
    font-size: inherit;
  }
  &.-bar {
    margin: 0;
    padding: 4px;
    background-color: $color-primary;
    text-align: center;
    color: $color-white;
  }
}

h1 {
  font-size: 2.778rem;
}

h3 {
  font-size: 1.667rem;
  color: $color-gray40;
}

h4 {
  font-size: 1.111rem;
  color: $color-gray40;
}

h5 {
  font-family: "interstate";
  font-weight: 700;
  font-size: 0.889rem;
  color: $color-gray40;
  text-transform: uppercase;
}

ul.no-bullet {
  list-style: none;
  margin: 10px 0 0;
  padding: 0;

  li {
    padding: 2px 0;
  }
}

.txt-blue {
  color: $color-health100;
}
.txt-orange {
  color: $color-health10;
}
.txt-center {
  text-align: center;
}
.txt-right {
  text-align: right;
}
.txt-uppercase {
  text-transform: uppercase;
}
.txt-error {
  color: $color-error;
}
.txt-info {
  color: $color-info;
}
.txt-error-light {
  color: $color-error-border;
}
.txt-not-allowed {
  cursor: not-allowed;
}
.txt-warning {
  font-style: italic;
  color: $color-warning;
}
.txt-link {
  color: $color-link;
  text-decoration: underline;
}
.txt-uppercase {
  text-transform: uppercase;
}
.txt-capitalize {
  text-transform: capitalize;
}
.txt-normal {
  font-weight: normal;
}
.txt-nowrap {
  white-space: nowrap;
}

/* Structural */
.container {
  margin: 0 auto;
  padding: 10px 20px;
  max-width: $max-width;
  @include clearfix;
  box-sizing: border-box;
  &.-loading {
    min-height: 100vh;
    position: relative;
  }
}

/* Global Variations */
.hide {
  display: none;
}
.disable {
  position: relative;
  &:before {
    content: " ";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background-color: $color-white;
    opacity: 0.5;
  }
}
.-shadow {
  box-shadow: 0px 0px 25px 0px rgba($color-black, 0.75);
  position: relative;
  z-index: 2;
}

fieldset {
  margin-bottom: 20px;
}
.group {
  display: flex;

  &.-center {
    justify-content: center;
  }
}

.background-line {
  border-top: 1px solid $color-gray10;
  span {
    transform: translateY(-50%);
    background-color: $color-base;
    display: inline-block;
    padding: 0 10px 0 0;
  }

  &.-darker {
    border-color: $color-base;

    span {
      background-color: $color-base-darken;
    }
  }
}

/* honeypot*/
#surname {
  display: none;
}

/* Quotes */
blockquote {
  margin: 5px 0 15px;
  color: #555555;
  padding: 20px 20px 10px 50px;
  border-left: 8px solid $color-primary;
  line-height: 1.6;
  position: relative;
  background: #ededed;

  p {
    margin: 0;
    font-size: 1.3em;
    font-style: italic;
  }
}
blockquote::before {
  content: "\201C";
  color: $color-detail;
  font-size: 6em;
  position: absolute;
  left: 10px;
  top: -25px;
}
blockquote::after {
  content: "";
}
blockquote cite {
  display: block;
  color: #333333;
  font-style: normal;
  margin-top: 1em;
}

ul.additive {
  list-style: none;
  padding-left: 10px;

  li {
    margin-bottom: 3px;
  }

  li:before {
    content: "\2013";
    margin-right: 5px;
  }
  li.-plus:before {
    content: "+";
    margin-right: 3px;
  }
}

@media (max-width: $screen-sm-max) {
  body {
    text-align: center;
  }
}

@media (min-width: $screen-lg-min) {
  .page-content {
    margin-left: 30px;
    padding: 30px;
    box-shadow: 0px 2px 30px #000006;
    display: flex;

    & > .content {
      margin-left: 30px;
      flex-grow: 1;
    }
  }
}
