// Typography variables
$font-family: "Interstate", Arial, sans-serif;
$font-size: 13px;
$line-height: 18px;

$color-base: #fff;
$color-base-darken: #03202d;
$color-primary: #de653a;
$color-detail: #18b2dc;
$color-link: #428bca;
$color-valid: #124601;

/* Error Colors */
$color-error-border: #cc0000;
$color-error-bg: #f7dbe0;
$color-error: #8e2323;
$color-warning-border: #f5b324;
$color-warning-bg: #f6e6c2;
$color-warning: #8a6d3b;
$color-info-border: $color-link;
$color-info-bg: #c9e5ef;
$color-info: #3294b6;
$color-success-border: #76bd22;
$color-success-bg: #dfeecc;
$color-success: #92c556;

$color-white: #ffffff;
$color-black: #000000;

/* 0-100% Health */
$color-health10: #fd9332;
$color-health20: #da8435;
$color-health30: #b87637;
$color-health40: #94673a;
$color-health50: #765b3c;
$color-health60: #1b4e60;
$color-health70: #1a6780;
$color-health80: #19819e;
$color-health90: #1998bc;
$color-health100: #1db2dc;

/* 100% Shades of Grey */
$color-gray10: #f3f3f3;
$color-gray20: #d6d6d4;
$color-gray40: #a5aeb3;
$color-gray50: #78878d;
$color-gray60: #50636d;
$color-gray70: #0a2633;
$color-gray80: #616265;
$color-gray90: #323232;
$color-gray95: #212121;

//Social Colors
$color-twitter: #5eb5e3;
$color-linkedIn: #3158a9;
$color-facebook: #0975a3;
$color-spiceworks: #fc5900;

//Media Queries
$max-width: 1920px;
$screen-lg-min: 1100px;
$screen-md-min: 992px;
$screen-sm-min: 768px;
$screen-xs-min: 580px;
$screen-xxs-min: 400px;

$screen-md-max: $screen-lg-min - 1;
$screen-sm-max: $screen-md-min - 1;
$screen-xs-max: $screen-sm-min - 1;
