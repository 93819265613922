.row {
    .column {
        padding: 10px;
    }
}

@media (min-width: $screen-sm-min) {
    .row {
        display: flex;
        align-items: stretch;
        .column {
            flex-grow: 1;
            /* Grid */
            &.-span1 {
                width: 1px / 12px * 100%;
            }
            &.-span2 {
                width: 2px / 12px * 100%;
            }
            &.-span3 {
                width: 3px / 12px * 100%;
            }
            &.-span4 {
                width: 4px / 12px * 100%;
            }
            &.-span5 {
                width: 5px / 12px * 100%;
            }
            &.-span6 {
                width: 6px / 12px * 100%;
            }
            &.-span7 {
                width: 7px / 12px * 100%;
            }
            &.-span8 {
                width: 8px / 12px * 100%;
            }
            &.-span9 {
                width: 9px / 12px * 100%;
            }
            &.-span10 {
                width: 10px / 12px * 100%;
            }
            &.-span11 {
                width: 11px / 12px * 100%;
            }
            &.-span12 {
                width: 12px / 12px * 100%;
            }

            &.-end {
                align-self: flex-end;
            }
        }
    }
}
