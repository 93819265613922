/* Buttons */
.button {
  margin: 10px 0;
  padding: 20px 30px;
  border: none;
  font-size: 18px;
  line-height: 26px;
  font-family: "interstate-condensed";
  font-weight: 700;
  text-decoration: none;
  text-transform: uppercase;
  color: $color-white;
  cursor: pointer;
  white-space: nowrap;
  display: inline-block;
  transition: padding 300ms;
  position: relative;

  .bubble {
    position: absolute;
    top: -8px;
    right: -8px;
    background-color: $color-detail;
    color: $color-white;
    padding: 4px;
    min-width: 20px;
    border-radius: 50%;
    font-size: 12px;
    line-height: 12px;
    text-align: center;
  }

  &:hover {
    text-decoration: none;
  }

  //Primary
  &.-primary {
    background: linear-gradient(to bottom, #e47944 0%, $color-primary 100%);
    color: $color-white;
    border-color: $color-primary;
    transition: background-color ease 400ms;
    &:hover,
    &:focus {
      background: linear-gradient(to bottom, $color-primary 0%, #e47944 100%);
      color: $color-white;
      border-color: $color-primary;
    }

    &.-border {
      border: 1px solid $color-primary;
      color: $color-primary;
      background: #fff;
      &:hover,
      &:focus {
        color: lighten($color-primary, 10%);
        border-color: lighten($color-primary, 10%);
      }
    }

    &:active,
    &:link,
    &:visited {
      color: $color-white;
    }
  }

  //Secondary
  &.-secondary {
    background: $color-detail;
    color: $color-white;
    border-color: $color-detail;
    transition: background-color ease 400ms;
    &:hover,
    &:focus {
      background: lighten($color-detail, 5%);
      color: $color-white;
      border-color: $color-detail;
    }

    &.-border {
      border: 1px solid $color-detail;
      color: $color-detail;
      background: #fff;
      &:hover,
      &:focus {
        color: lighten($color-detail, 10%);
        border-color: lighten($color-detail, 10%);
      }
    }

    &:active,
    &:link,
    &:visited {
      color: $color-white;
    }
  }

  //Error
  &.-error {
    background: $color-error;
    border-color: $color-error;
    color: $color-white;
    &:hover {
      background: lighten($color-error, 10%);
      border-color: $color-error;
      color: $color-white;
    }

    &.-border {
      border: 1px solid $color-error;
      color: $color-error;
      background: #fff;
      &:hover {
        color: lighten($color-error, 10%);
        border-color: lighten($color-error, 10%);
      }
    }
    &:active,
    &:link,
    &:visited {
      color: $color-white;
    }
  }

  //Default
  &.-default {
    border: 1px solid $color-gray40;
    color: $color-base;
    background: linear-gradient(to bottom, $color-white 0%, $color-gray10 100%);
    &:hover {
      color: darken($color-base, 10%);
      border-color: darken($color-gray20, 10%);
      background: linear-gradient(
        to bottom,
        darken($color-white, 5%) 0%,
        darken($color-gray10, 5%) 100%
      );
    }
    &:active,
    &:link,
    &:visited {
      color: $color-base;
    }

    &.-pressed {
      color: darken($color-base, 10%);
      border-color: darken($color-gray20, 10%);
      background: linear-gradient(
        to bottom,
        darken($color-white, 15%) 0%,
        darken($color-gray10, 15%) 100%
      );
    }
  }

  .svg-inline--fa {
    font-size: 15px;
  }

  //Default Color
  @extend .-default;

  &.-disabled,
  &:disabled,
  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &.-icon {
    .svg-inline--fa {
      margin-left: 15px;
      margin-right: -15px;
      font-size: 15px;
      vertical-align: bottom;
    }

    &.-left {
      .svg-inline--fa {
        margin-left: -15px;
        margin-right: 15px;
        font-size: 15px;
        vertical-align: bottom;
      }
    }
  }

  &.-small {
    padding: 5px 10px;
    font-size: 13px;
    line-height: 13px;

    .svg-inline--fa {
      font-size: 13px;
    }

    &.-icon {
      .svg-inline--fa {
        margin-left: 5px;
        margin-right: -5px;
      }
    }

    &.-left {
      .svg-inline--fa {
        margin-left: -2px;
        margin-right: 5px;
      }
    }
  }
  &.-large {
    padding: 12px 65px;
    font-size: 15px;
    line-height: 15px;
  }
  &.-full {
    width: 100%;
    text-align: center;
    display: block;
  }
  &.-text {
    margin: 0;
    padding: 0;
    color: $color-link;
    background: none;
    text-align: left;
    white-space: normal;
    font-size: 13px;
    line-height: 13px;
    border-color: transparent;

    &.-selected {
      color: $color-base;
      cursor: default;
    }
  }
  &.-accordion-control {
    margin: 0;
    padding: 0;
    width: 36px;
    height: 36px;
    border-color: transparent;
    // background: url("/images/accordion-control.png") center center;

    &.-active {
      transform: rotate(180deg);
    }
  }
  &.-default-color {
    color: $color-base;
  }
  &.-no-pointer {
    cursor: not-allowed;
  }
}

.button-group {
  width: 100%;
  display: flex;
  align-items: center;

  h1,
  h2,
  h3,
  h4 {
    flex-grow: 1;
  }
  .image-stream {
    margin-right: 10px;
  }

  .button {
    margin-left: 0px;
    margin-right: 10px;

    &.-right {
      margin-left: auto;
    }
  }

  /* Variations */
  &.-right {
    justify-content: flex-end;

    .button {
      margin-left: 10px;
      margin-right: 0px;
    }
  }
  &.-center {
    justify-content: center;

    .button:last-child {
      margin-right: 0;
    }
  }

  &.-stacked {
    flex-direction: column;
  }
  &.-margin-right .form-field {
    margin-right: 15px;
  }
}
