@import "styles/base/normalize";

// Base
@import "styles/base/variables";
@import "styles/base/mixins";
@import "styles/base/grid";
@import "styles/base/global";

@import "styles/components/button";
@import "styles/components/form-field";

.App {
  text-align: center;

  .App-header {
    background-color: #282c34;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    padding: 1em 0;
  }

  .App-container {
    padding: 2em;
  }

  .App-link {
    color: #61dafb;
  }
}

.bull-frame {
  iframe {
    height: 80vh;
    width: 80vw;
  }
}

.ingestion-modal {
  width: 400px;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(-50%, -50%);
  padding: 2em;
  background: white;
  min-height: 50vh;
  border: 1px solid #999;
  filter: drop-shadow(3px 3px 3px #999);
  textarea {
    min-height: 10em;
  }
  .react-datepicker-wrapper {
    width: 100%;
  }
}
